var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('dashboard.supply.title'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.supplies,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.day1",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.day1.replaceAll('\n', '<br>'))}})]}},{key:"item.day2",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.day2.replaceAll('\n', '<br>'))}})]}},{key:"item.day3",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.day3.replaceAll('\n', '<br>'))}})]}},{key:"item.day4",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.day4.replaceAll('\n', '<br>'))}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }