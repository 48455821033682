<script>
  import { Line, mixins } from 'vue-chartjs';
  const { reactiveProp } = mixins;

  export default {
    name: 'LineChart',
    extends: Line,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        required: true
      },
      options: {
        type: Object,
        required: false
      }
    },
    mounted () {
      // this.chartData is created in the mixin.
      // If you want to pass options please create a local options object
      const options = this.options;
      this.renderChart(this.chartData, options);
    }
  };
</script>
