<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t('dashboard.supply.title') }}
      </v-card-title>
      <v-data-table :headers="headers" :items="supplies" :loading="loading" hide-default-footer>
        <template #[`item.day1`]="{ item }">
          <span v-html="item.day1.replaceAll('\n', '<br>')" />
        </template>
        <template #[`item.day2`]="{ item }">
          <span v-html="item.day2.replaceAll('\n', '<br>')" />
        </template>
        <template #[`item.day3`]="{ item }">
          <span v-html="item.day3.replaceAll('\n', '<br>')" />
        </template>
        <template #[`item.day4`]="{ item }">
          <span v-html="item.day4.replaceAll('\n', '<br>')" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'LocationSupplyAnalytics',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        headers: [],
        supplies: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/location/supply', { timeout: 60000 });
          if (response.data !== undefined && response.data.supply !== undefined) {
            this.supplies = [];
            this.setHeaders(response.data.supply.labels);
            this.setForecast('miniBreads', response.data.supply.miniBreads);
            this.setForecast('breads', response.data.supply.breads);
            this.setForecast('miniSteaks', response.data.supply.miniSteaks);
            this.setForecast('steaks', response.data.supply.steaks);
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('dashboard.error'));
        }
        this.loading = false;
      },
      setHeaders (labels) {
        let day = 1;
        this.headers = [{
          text: '',
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'title'
        }].concat(labels.map((label) => {
          return {
            text: label,
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'day' + day++
          };
        }));
      },
      setForecast (name, data) {
        this.supplies.push({
          title: this.$i18n.t('dashboard.supply.data.' + String(name) + '.title'),
          day1: data[0],
          day2: data[1],
          day3: data[2],
          day4: data[3]
        });
      }
    }
  };
</script>
