<template>
  <v-container id="location-recipe">
    <h3>{{ $t('dashboard.recipe.description') }}</h3>
    <iframe :src="url" width="100%" height="550" />
  </v-container>
</template>
<script>
  export default {
    name: 'LocationRecipe',
    data () {
      return {
        loading: false,
        settingName: 'location_recipe',
        url: undefined
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        if (this.loading) {
          return;
        }

        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/setting?name=' + this.settingName, { timeout: 15000 });
          if (response.data !== undefined && response.data.value !== undefined) {
            this.url = response.data.value;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('dashboard.error'));
        }
        this.loading = false;
      }
    }
  };
</script>
