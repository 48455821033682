<template>
  <v-container id="analytics-performances" fluid tag="section">
    <v-row>
      <v-col v-if="delay && delay.preparation" cols="12" sm="6" lg="6">
        <base-material-stats-card :color="delay.preparation.color" :value="delay.preparation.title" :small-value="delay.preparation.subtitle" :title="$t('dashboard.performances.delay.preparation.title')" :sub-text="$t('dashboard.performances.delay.preparation.period')" icon="mdi-food" sub-icon="mdi-calendar-clock" />
      </v-col>
      <v-col v-if="delay && delay.waiting" cols="12" sm="6" lg="6">
        <base-material-stats-card :color="delay.waiting.color" :value="delay.waiting.title" :small-value="delay.waiting.subtitle" :title="$t('dashboard.performances.delay.waiting.title')" :sub-text="$t('dashboard.performances.delay.waiting.period')" icon="mdi-clock" sub-icon="mdi-calendar-clock" />
      </v-col>
      <v-col v-if="delay && delay.delivery" cols="12" sm="6" lg="6">
        <base-material-stats-card :color="delay.delivery.color" :value="delay.delivery.title" :small-value="delay.delivery.subtitle " :title="$t('dashboard.performances.delay.delivery.title')" :sub-text="$t('dashboard.performances.delay.delivery.period')" icon="mdi-truck-delivery" sub-icon="mdi-calendar-clock" />
      </v-col>
      <v-col v-if="lateOrders" cols="12" sm="6" lg="6">
        <base-material-stats-card :color="lateOrders.color" :value="lateOrders.title" :small-value="lateOrders.subtitle" :title="$t('dashboard.performances.lateOrders.title')" :sub-text="$t('dashboard.performances.lateOrders.period')" icon="mdi-folder" sub-icon="mdi-calendar-clock" />
      </v-col>
      <v-col v-if="!delay || Object.keys(delay).length === 0">
        {{ $t('dashboard.noresult') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { secondToHHmm } from '@/util/DateUtil';

  export default {
    name: 'LocationPerformancesAnalytics',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        delay: null,
        lateOrders: null
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/location/performances', { timeout: 60000 });
          this.processDataDelay(response.data);
          this.processDataLateOrders(response.data);
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('dashboard.error'));
        }
      },
      processDataDelay (data) {
        if (data.delay !== undefined) {
          this.delay = {};
          Object.keys(data.delay).forEach((type) => {
            this.delay[type] = {
              value: data.delay[type].value,
              avg: data.delay[type].avg
            };

            const delay = this.delay[type];
            if (delay.value !== undefined) {
              delay.title = secondToHHmm(delay.value);
            }

            if (delay.value !== undefined && delay.avg !== undefined && delay.avg > 0 && delay.value > 0) {
              delay.increase = ((delay.avg - delay.value) / Math.abs(delay.avg)) * 100;
            } else {
              delay.increase = 0;
            }

            if (delay.increase >= 0) {
              delay.color = 'success';
            } else if (delay.increase > -10) {
              delay.color = 'warning';
            } else {
              delay.color = 'fail';
            }

            delay.subtitle = delay.increase === 0 ? '' : '(' + (delay.increase > 0 ? '+' : '') + delay.increase.toFixed(2) + '%)';
          });
        }
      },
      processDataLateOrders (data) {
        if (data.lateOrders !== undefined) {
          this.lateOrders = data.lateOrders;

          if (this.lateOrders.value > 0) {
            this.lateOrders.title = String(this.lateOrders.value) + ' commandes';
          }

          if (this.lateOrders.value > 0) {
            this.lateOrders.color = 'fail';
          } else {
            this.lateOrders.color = 'success';
          }

          if (this.lateOrders.value === 0) {
            this.lateOrders.subtitle = this.$i18n.tc('dashboard.performances.lateOrders.subtitle', this.lateOrders.value);
          } else if (this.lateOrders.percentage !== 0) {
            this.lateOrders.subtitle = '(' + String(this.lateOrders.percentage) + '%)';
          }
        }
      }
    }
  };
</script>
