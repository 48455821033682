<template>
  <v-container id="table-products-sold">
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :max-days="365" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('dashboard.productsSold.table.title') }}
            <v-spacer />
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('dashboard.productsSold.table.search')" single-line hide-details />
            <v-spacer />
            <table-exporter :start-full-date="startFullDate" :end-full-date="endFullDate" :headers="headers" :items="products" />
            <table-printer element-id="table-products-sold" />
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="products" :search="search" :loading="loading" @current-items="getFilteredResults">
            <template slot="body.prepend">
              <tr>
                <th class="text-uppercase">
                  {{ $t('dashboard.productsSold.table.total') }}
                </th>
                <th class="text-center">
                  {{ total }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';

  export default {
    name: 'ProductsSoldAnalytics',
    components: { PeriodSelector, TableExporter, TablePrinter },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getTodayDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        loading: false,
        search: '',
        headers: [
          {
            text: this.$i18n.t('dashboard.productsSold.table.name'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('dashboard.productsSold.table.quantity'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'quantity'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['quantity'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        products: [],
        total: 0
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const fromDate = encodeURIComponent(this.startFullDate);
          const toDate = encodeURIComponent(this.endFullDate);
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/partner/product/sold?from=' + fromDate + '&to=' + toDate, { timeout: 60000 });
          if (response.data !== undefined && response.data.products !== undefined) {
            this.products = response.data.products;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
        this.loading = false;
      },
      getFilteredResults (items) {
        this.total = items.reduce((total, product) => total + product.quantity, 0);
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
