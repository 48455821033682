<template>
  <v-container id="table-order-reviews">
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :max-days="90" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col v-if="Object.keys(reviewCollection).length > 0" cols="8">
        <div class="tinyChart">
          <line-chart :chart-data="reviewCollection" :options="options" data-position="above" data-color="rgba(0, 0, 0, 1)" />
        </div>
      </v-col>
      <v-col v-if="avg || median" cols="4" class="text-h2">
        <div v-if="avg" class="box-result">
          {{ $t('dashboard.orderReviews.avg') }} : {{ avg }}/5
        </div>
        <div v-if="median" class="box-result">
          {{ $t('dashboard.orderReviews.median') }} : {{ median }}/5
        </div>
      </v-col>
      <v-col v-if="$can('read', 'Report.Activity')" cols="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('dashboard.orderReviews.subtitle') }}
            <v-spacer />
            <table-exporter :start-full-date="startFullDate" :end-full-date="endFullDate" :headers="headers" :items="reviews" />
            <table-printer element-id="table-order-reviews" />
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="reviews" :loading="loading">
            <template #[`item.order.createdAt`]="{ item }">
              {{ displayDateISO(item.order.createdAt) }}
            </template>
            <template #[`item.source`]="{ item }">
              {{ $t('dashboard.orderReviews.source.' + item.source) }}
            </template>
            <template #[`item.rating`]="{ item }">
              {{ item.rating }}/5
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import LineChart from '@/views/components/common/chart/LineChart';
  import { displayDateISO, getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';

  export default {
    name: 'OrderReviewsAnalytics',
    components: { LineChart, PeriodSelector, TableExporter, TablePrinter },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        loading: false,
        headers: [
          {
            text: this.$i18n.t('dashboard.orderReviews.table.date'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'order.createdAt'
          },
          {
            text: this.$i18n.t('dashboard.orderReviews.table.source'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'source'
          },
          {
            text: this.$i18n.t('dashboard.orderReviews.table.rating'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'rating'
          },
          {
            text: this.$i18n.t('dashboard.orderReviews.table.comment'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'comment'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['order.createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        reviewCollection: {},
        reviews: [],
        avg: undefined,
        median: undefined
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      displayDateISO: displayDateISO,
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const fromDate = encodeURIComponent(this.startFullDate);
          const toDate = encodeURIComponent(this.endFullDate);
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'order/reviews?from=' + fromDate + '&to=' + toDate, { timeout: 60000 });
          if (response.data !== undefined && response.data.reviews !== undefined) {
            this.avg = response.data.avg;
            this.median = response.data.median;
            this.reviews = response.data.reviews;
            const ratings = response.data.ratings;
            this.reviewCollection = {
              labels: Object.keys(ratings),
              datasets: [{ label: this.$i18n.t('dashboard.orderReviews.subtitle'), data: Object.values(ratings), borderColor: 'rgb(54, 162, 235)', backgroundColor: 'rgb(54, 162, 235)' }]
            };
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('dashboard.error'));
        }
        this.loading = false;
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
<style lang="sass">
.box-result
  height: 50%
  display: flex
  justify-content: center
  align-items: center
</style>
