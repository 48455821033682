<template>
  <v-container id="dashboard" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('dashboard.performances.title') }}</v-tab>
        <v-tab>{{ $t('dashboard.supply.title') }}</v-tab>
        <v-tab>{{ $t('dashboard.recipe.title') }}</v-tab>
        <v-tab>{{ $t('dashboard.inventory.title') }}</v-tab>
        <v-tab>{{ $t('dashboard.productsSold.title') }}</v-tab>
        <v-tab>{{ $t('dashboard.ingredientsSold.title') }}</v-tab>
        <v-tab>{{ $t('dashboard.productLabels.title') }}</v-tab>
        <v-tab>{{ $t('dashboard.orderReviews.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="1">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <location-performances-analytics :refresh="refreshLocationPerformances" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <location-supply-analytics :refresh="refreshSupply" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <location-recipe @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="4">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <location-inventory @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="5">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <products-sold-analytics :refresh="refreshProductsSold" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="6">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <ingredients-sold-analytics :refresh="refreshIngredientsSold" :loading="loading" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="7">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <product-labels :refresh="refreshProductLabels" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="8">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <order-reviews-analytics :refresh="refreshOrderReviews" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import LocationPerformancesAnalytics from '@/views/components/business/analytics/LocationPerformancesAnalytics';
  import LocationSupplyAnalytics from '@/views/components/business/analytics/LocationSupplyAnalytics';
  import LocationRecipe from '@/views/components/business/dashboard/LocationRecipe';
  import LocationInventory from '@/views/components/business/dashboard/LocationInventory';
  import ProductsSoldAnalytics from '@/views/components/business/analytics/ProductsSoldAnalytics';
  import IngredientsSoldAnalytics from '@/views/components/business/analytics/IngredientsSoldAnalytics';
  import ProductLabels from '@/views/components/business/dashboard/ProductLabels';
  import OrderReviewsAnalytics from '@/views/components/business/dashboard/OrderReviewsAnalytics';

  export default {
    name: 'Dashboard',
    components: { AlertNotification, LocationPerformancesAnalytics, LocationSupplyAnalytics, LocationRecipe, LocationInventory, ProductsSoldAnalytics, IngredientsSoldAnalytics, ProductLabels, OrderReviewsAnalytics },
    data () {
      return {
        alert: '',
        currentTab: 0,
        loading: false,
        refreshLocationPerformances: null,
        refreshSupply: null,
        refreshProductsSold: null,
        refreshIngredientsSold: null,
        refreshProductLabels: null,
        refreshOrderReviews: null
      };
    },
    mounted () {},
    methods: {
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
      },
      onTabChange (tab) {
        switch (tab) {
          case 0:
            this.refreshLocationPerformances = Date.now();
            this.loading = true;
            break;
          case 1:
            this.refreshSupply = Date.now();
            this.loading = true;
            break;
          case 4:
            this.refreshProductsSold = Date.now();
            break;
          case 5:
            this.refreshIngredientsSold = Date.now();
            break;
          case 6:
            this.refreshProductLabels = Date.now();
            break;
          case 7:
            this.refreshOrderReviews = Date.now();
            break;
        }
        this.currentTab = tab;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
