<template>
  <v-container id="productLabels" fluid tag="section">
    <alert-notification :message="alert" />
    <v-banner color="secondary" icon="mdi-firefox" dark outlined rounded class="mb-6 ma-auto" style="max-width: 500px;">
      <div>
        <h1>Utilisez Firefox pour l'impression</h1>
      </div>
    </v-banner>
    <v-card>
      <v-card-title>
        {{ $t('dashboard.productLabels.title') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('dashboard.productLabels.table.search')" single-line hide-details />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="labels" :search="table.search" :loading="loading">
        <template #[`item.actions`]="{ item }">
          <span>
            <v-icon medium @click="product=item,show=true">
              mdi-printer
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="80%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('dashboard.productLabels.form.print.title') }}
          </div>
        </template>

        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="6">
                {{ $t('dashboard.productLabels.form.print.field.count') }}
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="amount" type="number" required dense solo hide-details :clearable="true" />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('dashboard.productLabels.form.print.button.cancel') }}
            </v-btn>
            <v-btn color="success" @click.stop="print(),show=false">
              {{ $t('dashboard.productLabels.form.print.button.print') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';

  export default {
    name: 'ProductLabels',
    components: { AlertNotification },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        alert: '',
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('dashboard.productLabels.table.column.barcode'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'barcode'
            },
            {
              text: this.$i18n.t('dashboard.productLabels.table.column.title'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'title'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        labels: [],
        show: false,
        product: undefined,
        amount: 1000
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      print (item) {
        if (this.product !== undefined && this.amount >= 1) {
          window.open(process.env.VUE_APP_BASE_APP_URL + 'label.html?count=' + String(this.amount)
                        + '&title=' + encodeURIComponent(this.product.title) + '&barcode=' + encodeURIComponent(this.product.barcode),
                      'label', 'location=yes,height=570,width=520,resizable,scrollbars,status');
        }
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'product/labels');
          if (response.data !== undefined && response.data.labels !== undefined) {
            this.labels = response.data.labels;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
