<template>
  <v-container id="ingredients-sold-analytics">
    <advanced-table :title="title" :headers="headers" :endpoint="endpoint" :transform="transform" :loading="loading" :refresh="refresh"
                    :feature-exporter="false" :feature-printer="false" @success="success" @fail="fail"
    />
  </v-container>
</template>

<script>
  import AdvancedTable from '@/views/components/common/AdvancedTable';

  export default {
    name: 'IngredientsSoldAnalytics',
    components: { AdvancedTable },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        title: this.$t('dashboard.ingredientsSold.table.title'),
        headers: [
          {
            text: this.$i18n.t('dashboard.ingredientsSold.table.name'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('dashboard.ingredientsSold.table.quantity'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'quantity'
          }
        ],
        endpoint: 'analytics/partner/ingredient/sold',
        transform: {}
      };
    },
    methods: {
      success () {
        this.$emit('success');
      },
      fail (error, message) {
        this.$emit('fail', error, message);
      }
    }
  };
</script>
